
import {onBeforeUnmount, onMounted} from "vue"
import {useStore} from "vuex"

export function useCurrentScreen (screenName) {

  const store = useStore()

  onMounted(() => {
    store.commit('setCurrentScreen', screenName)
  })

  onBeforeUnmount(() => {
    store.commit('setCurrentScreen', null)
  })

}

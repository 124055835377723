import {onBeforeUnmount, watch} from "vue"
import {useStore} from "vuex"
import {useI18n} from "vue-i18n"

export function useScreenDocumentTitle (screenName: string) {
  const store = useStore()
  const { t } = useI18n()

  const storeNamespace = screenName
  const watchingItems: any[] = []
  let stopWatchingDocumentTitle

  const textareaElement = document.createElement('textarea')
  const getTicketTitle = (ticket) => {
    if (!ticket?.title) {
      return ''
    }
    textareaElement.innerHTML = ticket.title_orig || ticket.title
    return textareaElement.textContent
  }

  const setScreenDocumentTitle = () => {
    let title = '',
      ticketId,
      ticket,
      ticketTitle,
      project,
      projectTitle

    switch (screenName) {
      case 'ticket':
        ticket = store.getters[storeNamespace + '/ticket']
        ticketTitle = getTicketTitle(ticket)
        project = store.getters['project']
        projectTitle = project?.name
        title = (ticketTitle ? ticketTitle + ' - ' : '') + (projectTitle || '')
        break
      case 'dashboard':
        ticketId = store.getters[storeNamespace + '/selectedItemId']
        ticket = store.getters[storeNamespace + '/getItemById'](ticketId)
        ticketTitle = getTicketTitle(ticket)
        title = (ticketTitle ? ticketTitle + ' - ' : '') + t('Dashboard View')
        break
      case 'board':
        project = store.getters['project']
        projectTitle = project?.name
        title = (projectTitle ? projectTitle + ' - ' : '') + t('Board View')
        break
      case 'listview':
        ticketId = store.getters[storeNamespace + '/selectedItemId']
        ticket = store.getters[storeNamespace + '/getItemById'](ticketId)
        ticketTitle = getTicketTitle(ticket)
        project = store.getters['project']
        projectTitle = project?.name
        title = (ticketTitle ? ticketTitle + ' - ' : '') + (projectTitle ? projectTitle + ' - ' : '') + t('List View')
        break
      case 'report_general':
        project = store.getters['project']
        projectTitle = project?.name
        title = (projectTitle ? projectTitle + ' - ' : '') + t('Reports')
        break
      case 'project_info':
        project = store.getters['project']
        projectTitle = project?.name
        title = (projectTitle ? projectTitle + ' - ' : '') + t('Settings') + ' - ' + t('Project Info')
        break
      case 'workflow_designer':
        project = store.getters['project']
        projectTitle = project?.name
        title = (projectTitle ? projectTitle + ' - ' : '') + t('Settings') + ' - ' + t('Workflow Designer')
        break
      case 'project_team':
        project = store.getters['project']
        projectTitle = project?.name
        title = (projectTitle ? projectTitle + ' - ' : '') + t('Settings') + ' - ' + t('Project Team')
        break
      case 'ticket_designer':
        project = store.getters['project']
        projectTitle = project?.name
        title = (projectTitle ? projectTitle + ' - ' : '') + t('Settings') + ' - ' + t('Ticket Form Designer')
        break
      case 'automation':
        project = store.getters['project']
        projectTitle = project?.name
        title = (projectTitle ? projectTitle + ' - ' : '') + t('Automation')
        break
      case 'people':
        title = t('People list')
        break
    }
    document.title = (title ? title + ' | ' : '') + 'smartQ'
  }

  switch (screenName) {
    case 'ticket':
      //watchingItems.push(() => store.getters['ticket']?.title)
      stopWatchingDocumentTitle = watch(() => store.getters[storeNamespace + '/ticket'], setScreenDocumentTitle, {deep: true})
      break
    case 'dashboard':
      watchingItems.push(() => store.getters[storeNamespace + '/selectedItemId'])
      break
    case 'board':
    case 'report_general':
    case 'project_info':
    case 'workflow_designer':
    case 'project_team':
    case 'ticket_designer':
    case 'automation':
      watchingItems.push(() => store.getters['project'])
      break
    case 'listview':
      watchingItems.push(() => store.getters[storeNamespace + '/selectedItemId'])
      watchingItems.push(() => store.getters['project'])
      break
  }

  setScreenDocumentTitle()
  if (watchingItems.length) {
    stopWatchingDocumentTitle = watch(watchingItems, setScreenDocumentTitle)

    onBeforeUnmount(() => {
      stopWatchingDocumentTitle()
    })
  }


}
